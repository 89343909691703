/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { ApolloProvider } from '@apollo/client'
import { useEffect, startTransition, StrictMode } from 'react'
import * as Sentry from '@sentry/remix'
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import { hydrateRoot } from 'react-dom/client'
import { createApolloClient } from '~/config/apollo'

// Load Apollo dev tools in development mode
if (process.env.NODE_ENV === 'development') {
  import('@apollo/client/dev').then(({ loadErrorMessages, loadDevMessages }) => {
    loadErrorMessages()
    loadDevMessages()
  })
}

const apolloClient = createApolloClient(null)

Sentry.init({
  dsn: window?.ENV?.SENTRY_DSN,
  environment: window?.ENV?.SENTRY_ENVIRONMENT,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches
    }),
    // Replay is only available in the client
    Sentry.replayIntegration()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  beforeSend: event => {
    const allowedEnvironments = ['production', 'staging']
    const currentEnvironment = window?.ENV?.SENTRY_ENVIRONMENT || 'development'
    if (allowedEnvironments.includes(currentEnvironment)) {
      return event
    }
    return null
  }
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <ApolloProvider client={apolloClient}>
        <RemixBrowser />
      </ApolloProvider>
    </StrictMode>
  )
})
